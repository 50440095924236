(function () {
  'use strict';

  /**
   * @ngdoc object
   * @name home.phases.controller:PhasesCtrl
   *
   * @description
   *
   */
  angular
      .module('neo.home.phases')
      .controller('PhasesCtrl', PhasesCtrl);

  function PhasesCtrl($filter, phases) {
    var vm = this;
    // TODO: refactor when we have date general formatting
    angular.forEach(phases, function (phase) {
      phase.nameOrder = $filter('lowercase')(phase.name);
      phase.iniDateOrder = phase.iniDate;
      phase.iniDate = moment(phase.iniDate).format('L');
      phase.endDateOrder = phase.endDate;
      phase.endDate = moment(phase.endDate).format('L');
      phase.type = $filter('translate')('PHASES.PHASES_TYPES.' + phase.type);
    });

    vm.ctrlName = 'PhasesCtrl';
    vm.data = {
      title: 'PHASES.PHASE_LIST',
      notFoundMessage: 'RESULT_NOT_FOUND',
      headers: [
        {id: 'name', name: 'NAME', canOrder: true, orderBy: 'nameOrder'},
        {id: 'iniDate', name: 'INI_DATE', canOrder: true, orderBy: 'iniDateOrder'},
        {id: 'endDate', name: 'END_DATE', canOrder: true, orderBy: 'endDateOrder'},
        {id: 'type', name: 'PHASES.PHASE_TYPE', canOrder: true}
      ],
      state: {slink: 'home.phases.phase', params: [{key: 'phaseId', value: 'id'}]},
      defaultOrderBy: 'nameOrder',
      filters: undefined,
      values: phases
    };
  }
}());

